<template>
  <div class="toast" :class="[isVertical ? 'toast-vertical' : '']" v-show="isshow">{{ text }}</div>
</template>

<script>
import { toRefs, reactive } from 'vue'

export default {
  setup() {
    let state = reactive({
      text: '',
      isVertical: false,
      isshow: true
    });
    let timeid = null;

    function show() {
      state.isshow = true;
      clearTimeout(timeid);
      timeid = setTimeout(() => {
        state.isshow = false;
      }, 3000);
    }
    show()
    return {
      ...toRefs(state),
      show
    };
  }
};
</script>

<style lang="scss" scoped>
.toast {
  min-width: 2.1rem;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 0.6rem;
  text-align: center;
  border-radius: 0.12rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  font-size: 0.25rem;
  padding: 0.05rem 0.2rem;
  white-space: nowrap;
}
.toast-vertical{
  min-width: 38vw;
  max-width: 70vw;
  line-height: 1;
  padding: 5vw 0;
  font-family: PingFangSC-Regular;
	font-size: 5vw;
}
</style>
