import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
const Report = () => import('../views/report/index.vue')
const leafletsVertical = () => import('../views/leaflets/vertical.vue')
const leafletsHorizontal = () => import('../views/leaflets/horizontal.vue')
const oceanReplay = () => import('../views/ocean_report/index.vue')
const freeReplay = () => import('../views/freeplay_replay/index.vue')


const setTitle = (route: any) => {
  document.title = route.meta.title ? route.meta.title : 'musicscore-register'
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: {
      title: '回放报告'
    }
  },
  {
    path: '/oceanReplay',
    name: 'oceanReplay',
    component: oceanReplay,
    meta: {
      title: '海洋回放demo'
    }
  },
  {
    path: '/freeReplay',
    name: 'freeReplay',
    component: freeReplay,
    meta: {
      title: '海洋算法回放demo'
    }
  },
  {
    path: '/leaflets/vertical',
    name: 'LeafletsVertical',
    component: leafletsVertical,
    meta: {
      title: '小叶子AI钢琴灯'
    }
  },
  {
    path: '/leaflets/horizontal',
    name: 'LeafletsHorizontal',
    component: leafletsHorizontal,
    meta: {
      title: '小叶子AI钢琴灯'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach(route => {
  setTitle(route)
})

export default router
