import toastComponent from './index.vue'

import { createApp } from 'vue';

let instance: any;
const toast = function(obj: any) {
  let text = ''
  let isVertical = false
  if (typeof obj === 'object') {
    text = obj.text
    isVertical = obj.isVertical
  } else {
    text = obj
  }
  if (instance) {
    instance.isVertical = isVertical
    instance.text = text;
    instance.show();
    return;
  }
  instance = createApp(toastComponent).mount(document.createElement('div'));
  instance.text = text;
  instance.isVertical = isVertical
  document.body.appendChild(instance.$el);
};

export default toast;
