import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/reset.scss'

import 'swiper/swiper.min.css'
import toast from '@/components/toast/index'

const app = createApp(App)
app.config.globalProperties.toast = toast

app.use(router)
app.use(store)

app.mount('#app')

function fnResize () {
  document.documentElement.style.fontSize = window.innerWidth / 7.5 + 'px'
  window.onresize = () => {
    document.documentElement.style.fontSize = document.documentElement.offsetWidth / 7.5 + 'px'
  }
}

fnResize()
